/**
 * add-for-dialog  基于@minxins/add
 * 为了【新增】弹窗功能而做的公用函数，方便mixin组合进入具体的【新增型views】
 * @author suyonggang
 */
export default {
    data() {
        return {
            isLoading: false,
            // 标段下拉
            bidOptions: [],
        }
    },
    methods: {
        // 关闭当前页面
        onClose() {
            this.$emit("onClose");
        },
        // 提交表单
        async onSubmit(isGoNext) {
            let self = this;
            if (self.isLoading) return; //防止重复提交
            self.$refs.form.validate(async valid => {
                if (!valid) return;
                self.isLoading = true;
                try {
                    await self.submit().then(res => {
                        self.$notify({
                            title: "成功",
                            message: `${self.nav[0].name}成功`,
                            type: "success"
                        });
                        self.onClose();
                        if (isGoNext !== true) {
                            self.onClose();
                        } else {
                            self.form = {};
                            this.$refs["form"].resetFields();
                        }
                    });

                } catch (e) {
                    console.error(e);
                } finally {
                    self.isLoading = false;
                }
            });
        },

        // 选择时为标段id
        handleBidChage(val) {
            this.form.bidId = val[1];
        },
    },

    // async activated() {
    //     this.log("dialog's view is activated!")
    //     // 每次打开窗口-初始化
    //     this.$refs["form"].resetFields();
    // },
    async mounted() {
        // this.log("dialog's view is mounted!")
        this.bidOptions = await this.$api_hw.common_getBidNameAndIdList({});
    }
}